import './Resume.css';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import Divider from './Divider';

const ResumeDiv = styled.div`
  @media screen and (min-width: 1000px) {
    width: 65%;
    position: absolute;
    right: 0px;
    top: 0px;
    padding-left: 60px;
  }
`;

const ResumeInnerDiv = styled.div`
  padding: 95px 20px 0;
`;

const ResumeContentsDiv = styled.div`
  width: 100%;
  height: auto;
`;

const ExperienceDiv = styled.div`
  color: #333333;
  margin-bottom: 40px;
`;

const HeaderDiv = styled.div``;

const HeaderUl = styled.ul`
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;
const HeaderLi = styled.li`
  display: inline-block;
`;

const JobDesriptionUl = styled.ul`
  margin-top: 15px;
`;
const JobDesriptionLi = styled.li`
  margin-left: 15px;
  margin-bottom: 10px;
`;

const EducationDiv = styled.div`
  /* margin-top: 30px; */
`;

interface CareerType {
  jobTitle: string;
  companyName: string;
  location: string;
  duration: string;
  JD: string[];
}

function Career({ jobTitle, companyName, location, duration, JD }: CareerType) {
  return (
    <div id='resume' style={{ marginBottom: '30px' }}>
      <HeaderDiv>
        <HeaderUl>
          <HeaderLi style={{ minWidth: '200px' }}>
            <b>{jobTitle}</b>
          </HeaderLi>
          <HeaderLi>
            <u>
              <b>{companyName}</b>
            </u>
          </HeaderLi>
          <div style={{ minWidth: '275px', textAlign: 'right' }}>
            <HeaderLi
              style={{
                fontSize: '12px',
                fontStyle: 'italic',
                paddingRight: '10px',
              }}
            >
              {location}
            </HeaderLi>
            <HeaderLi>
              <b>{duration}</b>
            </HeaderLi>
          </div>
        </HeaderUl>
      </HeaderDiv>
      <JobDesriptionUl>
        {JD.map((e) => (
          <JobDesriptionLi key={e}>{e}</JobDesriptionLi>
        ))}
      </JobDesriptionUl>
    </div>
  );
}

const samsungFullTime = [
  // 'Currently primarily working on Front-end side of Samsung Browser targeting for PC browser using Polymer js',
  'Implemented Quick Access Page of Samsung Internet, which is shown as the first page of Samsung Internet. QAP is provided for user convenience targeting more than 40 countries in Asia, Europe and America, and also provides news content, which is used by 10+ millions of users per day.',
  'Implemented news contents of Quick Access Page using vanillaJS, AWS, Webpack, CICD with Git Actions, and lighthouse.',
  'Reported directly to CPO: Developed the Samsung Internet dashboard that provides a rich visual summary of active users, version and device distribution of Samsung Browser. Used by the board of directors in the decision-making process.',
  'Implemented dashboard of Samsung browser using React, Redux, Recharts, Git Actions, Webpack, and Jest.',
  'Continuous Integration/Deployment Pipeline Integration, pull requests, code reviews, performance testing, unit/integration testing for Quick Access Page of Samsung Internet.',
];
const samsungIntern = [
  'Implemented news feed web application and CI/CD with React, Webpack, CircleCi, AWS, Jest, Enzyme, and ESLint.',
  'Worked on front-end of Samsung internet to provide users with more dynamic user experience regardless of screen size of a device.',
  'Constructed CI/CD to combine continuous integration and continuous deployment to automate building, testing, and deployment of the web application.',
];

const skill1 =
  'Java | JavaScript | TypeScript | C | Node | Express | React | Redux | NoSQL | Git Actions | Git';
const skill2 =
  'CI/CD | Jest | Unit Testing | Recharts | Lighthouse | Sentry | AWS | Web Development';

const skill3 = 'Frontend | English, Korean – professional proficiency or above';
function Resume() {
  const [fade, setFade] = useState('');

  useEffect(() => {
    setFade('end');

    return () => {
      setFade('');
    };
  });

  return (
    <ResumeDiv className={`start ${fade}`}>
      <ResumeInnerDiv>
        <ResumeContentsDiv>
          <ExperienceDiv>
            <Divider text='Experience' />
            <div style={{ marginTop: '40px' }}>
              <Career
                jobTitle='Software Engineer'
                companyName='Samsung'
                location='Suwon, South Korea'
                duration='2021/01 - Current'
                JD={samsungFullTime}
              />
              <Career
                jobTitle='Software Engineer, Intern'
                companyName='Samsung'
                location='Suwon, South Korea'
                duration='2020/06 - 2020/08'
                JD={samsungIntern}
              />
            </div>
          </ExperienceDiv>
          <div id='skills-div' style={{ margin: '40px 0px', color: '#333' }}>
            <Divider text='Skills' />
            <JobDesriptionUl>
              <JobDesriptionLi>{skill1}</JobDesriptionLi>
              <JobDesriptionLi>{skill2}</JobDesriptionLi>
              <JobDesriptionLi>{skill3}</JobDesriptionLi>
            </JobDesriptionUl>
          </div>
          <EducationDiv>
            <Divider text='Education' />
            <div style={{ marginTop: '40px', color: '#333333' }}>
              <Career
                jobTitle='Bachelor of Science'
                companyName='Georgia Institute of Technology'
                location='Atlanta, GA, USA'
                duration='High Honor'
                JD={['Major in Computer Science (Graduate in December 2020)']}
              />
            </div>
          </EducationDiv>
        </ResumeContentsDiv>
      </ResumeInnerDiv>
    </ResumeDiv>
  );
}

export default Resume;
