import './Topbar.css';
import Typewriter from 'typewriter-effect';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import logo from '../images/dark.png';
import hello from '../images/hello.png';
import hamburger from '../images/bars-solid.svg';
import SideBar from './SideBar';

function Topbar({
  isSideBarActive,
  setIsSideBarActive,
}: {
  isSideBarActive: boolean;
  setIsSideBarActive: any;
}) {
  const navigate = useNavigate();
  const [isTabActive, setIsTabActive] = useState([false, false, false]);
  const location = useLocation();

  const handleTopBarClick = (e: any) => {
    if (e.target.id === 'topbar-inner') {
      setIsSideBarActive(false);
    }
  };

  useEffect(() => {
    const pathName = location.pathname;
    if (pathName === '/') {
      setIsTabActive([true, false, false]);
    } else if (pathName === '/resume') {
      setIsTabActive([false, true, false]);
    } else if (pathName === '/contact') {
      setIsTabActive([false, false, true]);
    }
  }, [location]);

  return (
    <>
      <div id='topbar-outer' role='presentation' onClick={handleTopBarClick}>
        <div id='topbar-inner'>
          <div
            role='presentation'
            onClick={() => setIsSideBarActive(false)}
            onKeyDown={() => setIsSideBarActive(false)}
            id='logo'
          >
            {/* <Link to='/'>
              <img src={logo} alt='' />
            </Link> */}
            {/* <span id='type-writer'>
              <Typewriter
                options={{
                  strings: ['Hello, World!'],
                  autoStart: true,
                  loop: true,
                }}
              />
            </span> */}
          </div>
          <div
            role='presentation'
            onClick={() => setIsSideBarActive(!isSideBarActive)}
            onKeyDown={() => setIsSideBarActive(!isSideBarActive)}
            id='hamburger'
          >
            <img src={hamburger} alt='' />
          </div>
          <div id='top-list'>
            <ul>
              <li
                className={`${isTabActive[0]}`}
                onClick={() => {
                  navigate('/');
                }}
                onKeyDown={() => {
                  navigate('/');
                }}
              >
                Home
              </li>
              <li
                className={`${isTabActive[1]}`}
                onClick={() => {
                  navigate('/resume');
                }}
                onKeyDown={() => {
                  navigate('/resume');
                }}
              >
                Resume
              </li>
              <li
                className={`${isTabActive[2]}`}
                onClick={() => {
                  navigate('/contact');
                }}
                onKeyDown={() => {
                  navigate('/contact');
                }}
              >
                Contact
              </li>
            </ul>
          </div>
        </div>
      </div>
      <SideBar
        isSideBarActive={isSideBarActive}
        setIsSideBarActive={setIsSideBarActive}
      />
    </>
  );
}

export default Topbar;
