import './Contact.css';
import { useEffect, useState } from 'react';
import location from '../images/location-dot-solid.svg';
import email from '../images/envelope-regular.svg';
import phone from '../images/mobile-solid.svg';
import Divider from './Divider';

function Contact() {
  const [fade, setFade] = useState('');

  useEffect(() => {
    setFade('end');
    return () => {
      setFade('');
    };
  }, []);

  return (
    <div id='contact-section' className={`start ${fade}`}>
      <div id='contact-inner'>
        <Divider text='GET IN TOUCH'></Divider>
        <div id='short-info'>
          <ul>
            <li>
              <div className='list-inner'>
                <img src={location} alt='location'></img>
                <span>Hwaseong-si, Gyeonggi-do, Korea</span>
              </div>
            </li>
            <li>
              <div className='list-inner'>
                <img src={email} alt='email'></img>
                <span>taewon2711@gmail.com</span>
              </div>
            </li>
            <li>
              <div className='list-inner'>
                <img src={phone} alt='email'></img>
                <span>+82 10 5816 5103</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Contact;
