import styled from 'styled-components';

const ContactTitle = styled.div`
  width: 100%;
  position: relative;
  color: #333;
  letter-spacing: 4px;
  font-weight: 700;
  overflow: hidden;
`;

const Span = styled.span`
  &:after {
    content: '';
    width: 5000px;
    height: 1px;
    background-color: #7d7789;
    position: absolute;
    top: 50%;
    margin-left: 15px;
    text-transform: uppercase;
  }
`;

function Divider({ text }: { text: string }) {
  return (
    <ContactTitle>
      <Span>{text}</Span>
    </ContactTitle>
  );
}

export default Divider;
