import './MainPart.css';
import { Routes, Route, Link } from 'react-router-dom';
import Home from './Home';
import Contact from './Contact';
import Resume from './Resume';

/*
Home
Resume
Portfolio
Contact
*/

function MainPart({ setIsSideBarActive }: { setIsSideBarActive: any }) {
  return (
    <>
      <div id='author-image'></div>
      <div
        id='main-content'
        role='presentation'
        onClick={() => setIsSideBarActive(false)}
        onKeyDown={() => setIsSideBarActive(false)}
      >
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/resume' element={<Resume />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='*' element={<div>Not Found!</div>} />
        </Routes>
      </div>
    </>
  );
}

export default MainPart;
