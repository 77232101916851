import './Home.css';
import Typewriter from 'typewriter-effect';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Divider from './Divider';

function Home() {
  const [fade, setFade] = useState('');

  useEffect(() => {
    setFade('end');

    return () => {
      setFade('');
    };
  }, []);

  return (
    <div id='home' className={`start ${fade}`}>
      <div className='content'>
        <h3>Taewon Kong</h3>
        <span className='line'></span>
        <div id='typewriter'>
          <span>
            <strong>Front-end</strong>
          </span>
          <span>
            <Typewriter
              options={{
                strings: ['Developer', 'Software Engineer'],
                autoStart: true,
                loop: true,
              }}
            />
          </span>
        </div>
        <div id='contact-btn'>
          <Link to='/contact'>Get in Touch</Link>
        </div>
      </div>
    </div>
  );
}

export default Home;
