import './SideBar.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import pic from '../images/R5__3871.jpg';
import useWindowDimensions from './customHooks/useWindowDimensions';

function SideBar({
  isSideBarActive,
  setIsSideBarActive,
}: {
  isSideBarActive: boolean;
  setIsSideBarActive: any;
}) {
  const navigate = useNavigate();
  const { height, width } = useWindowDimensions();
  const [isTabActive, setIsTabActive] = useState([false, false, false]);
  const location = useLocation();

  useEffect(() => {
    if (width >= 1000) {
      setIsSideBarActive(false);
    }
  }, [width]);

  useEffect(() => {
    const pathName = location.pathname;
    if (pathName === '/') {
      setIsTabActive([true, false, false]);
    } else if (pathName === '/resume') {
      setIsTabActive([false, true, false]);
    } else if (pathName === '/contact') {
      setIsTabActive([false, false, true]);
    }
    console.log(pathName);
  }, [location]);

  return (
    <div id='sidebar' className={isSideBarActive ? 'active' : 'inactive'}>
      <div id='inner'>
        <div id='wrapper'>
          <div id='avatar'>
            <img src={pic} alt='my-pic'></img>
          </div>
          <div id='menu-list'>
            <ul>
              <li
                className={`${isTabActive[0]}`}
                onClick={() => {
                  navigate('/');
                }}
                onKeyDown={() => {
                  navigate('/');
                }}
              >
                Home
              </li>
              <li
                className={`${isTabActive[1]}`}
                onClick={() => {
                  navigate('/resume');
                }}
                onKeyDown={() => {
                  navigate('/resume');
                }}
              >
                Resume
              </li>
              <li
                className={`${isTabActive[2]}`}
                onClick={() => {
                  navigate('/contact');
                }}
                onKeyDown={() => {
                  navigate('/contact');
                }}
              >
                Contact
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SideBar;
