import './App.css';
import Topbar from 'components/Topbar';
import MainPart from 'components/MainPart';
import { useState } from 'react';
import { Helmet } from 'react-helmet';

function App() {
  const [isSideBarActive, setIsSideBarActive] = useState(false);

  return (
    <div className='App'>
      <Helmet>
        <title>Ted Kong</title>
        <meta name='Ted Kong portfolio webpage' content='Portfolio' />
      </Helmet>
      <Topbar
        isSideBarActive={isSideBarActive}
        setIsSideBarActive={setIsSideBarActive}
      />
      <MainPart setIsSideBarActive={setIsSideBarActive} />
    </div>
  );
}

export default App;
